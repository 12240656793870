import ru from 'core/lang/ru.json';

export const SUCCESS_SUFFIX = '_SUCCESS';
export const ERROR_SUFFIX = '_FAIL';

export const LOAD_STATUSES = {
  INIT: 0,
  LOADING: 11,
  LOADED: 111
};

export const config = {
  host: process.env.REACT_APP_SITE_HOST || 'http://localhost:11111',
  language: ru,
  storageName: 'persistantMAStateCacheTestStorage',
};

export const getInitialState = () => {
  return {
    competition: null,
    lang: config.language,
    media: {
      files: [],
    },
    routing: { locationBeforeTransitions: null },
    user: null,
    participant: {
      current_weight: 0,
      email: '',
      gender: 2,
      rate: 1,
      status: 2,
    }
  };
};

export const initialState = getInitialState();